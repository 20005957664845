import React, { useEffect, useState, useContext } from "react";
import {
  CardExpandContainer,
  CardExpandContainerHeader,
  CardExpandContainerHeaderContent,
  CardExpandContainerBody,
  ModalContainerHeaderButtonIcon,
  ModalContainerHeaderButton,
  ButtonWrapper,
} from "./styled";

import Button from "components/Form/Button";
import Input from "components/Form/Input";
import { Update } from "services/campaigns";
import SuccessToast from "components/Toasts/Success";
import ErrorToast from "components/Toasts/Error";

import { CoreContext } from "context/CoreContext";

export default function CardPosts({ closeable, campaign, reload }) {
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [disabledCreatePosts, setDisabledCreatePosts] = useState(true);
  const [post, setPost] = useState({});
  const [step, setStep] = useState(0);
  const { modal, setModal, user } = useContext(CoreContext);


  const removePost = (id) => {
    const postCopy = structuredClone(post);

    Reflect.deleteProperty(postCopy, id);

    setPost(postCopy);
  };

  const setPostTitle = (id, title) => {
    const postCopy = structuredClone(post);

    postCopy[id].title = title;

    setPost(postCopy);
  };

  const setPostCodeBoost = (id, boostCode) => {
    const postCopy = structuredClone(post);

    postCopy[id].boostCode = boostCode;

    setPost(postCopy);
  };

  const action = async () => {
    if (disabled) {
      return;
    }

    setSending(true);

    const linksPosts = message.split("\n");
    const createdAt = new Date()
    const createdBy = user

    try {
      const posts = Object.fromEntries(
        linksPosts.map((postLink) => {

          const id = crypto.randomUUID();

          if (postLink.includes("tiktok")) {
            return [
              id,
              {
                id,
                link: postLink,
                socialNetwork: "tiktok",
                createdAt,
                createdBy
              },
            ];
          }

          if (postLink.includes("instagram")) {
            return [
              id,
              {
                id,
                link: postLink,
                socialNetwork: "instagram",
                createdAt,
                createdBy
              },
            ];
          }

          if (postLink.includes("youtube")) {
            return [
              id,
              {
                id,
                link: postLink,
                socialNetwork: "youtube",
                createdAt,
                createdBy
              },
            ];
          }
        })
      );

      setPost(posts);
      setStep(1);

      setSending(false);
    } catch (error) {
      setSending(false);

      ErrorToast({
        message: "Uma ou mais Publicações não encontradas.",
      });
    }
  };

  const createPosts = async () => {
    if (disabledCreatePosts) {
      return;
    }

    await Update(
      {
        data: {
          posts: {
            ...campaign.posts,
            ...post,
          },
        },
      },
      campaign.id
    );

    SuccessToast({ message: "Publicações adicionadas com sucesso" });

    reload();

    closeable();
  };

  useEffect(() => {
    if (!message) {
      setDisabled(true);
      return;
    }

    setDisabled(false);
  }, [message]);

  // useEffect(() => {
  //   if (campaign?.type === "edit") {
  //     setMessage(campaign?.post?.link)
  //     setPost({
  //       [campaign?.post?.id]: {
  //         ...campaign?.post,
  //       },
  //     })
  //     // setPostTitle(campaign?.post?.id, campaign?.post?.title)
  //     // setPostCodeBoost(campaign?.post?.id, campaign?.post?.boostCode)
  //     action()
  //   }
  // }, []);

  useEffect(() => {
    if (
      !post ||
      Object.values(post).some((post) => !post.title)
    ) {
      setDisabledCreatePosts(true);
      return;
    }

    setDisabledCreatePosts(false);
  }, [post]);

  return (
    <CardExpandContainer closeable={closeable}>
      <CardExpandContainerHeader>
        <CardExpandContainerHeaderContent></CardExpandContainerHeaderContent>
        {closeable ? (
          <ModalContainerHeaderButton>
            <Button outline nospace onClick={closeable}>
              <ModalContainerHeaderButtonIcon icon={"close"} />
            </Button>
          </ModalContainerHeaderButton>
        ) : null}
      </CardExpandContainerHeader>
      <CardExpandContainerBody>
        {step === 0 && (
          <>
            <h4>Adicionar Publicações</h4>
            <div>
              <Input
                type={"textarea"}
                label={"Links das publicações *"}
                placeholder="Insira em cada linha os links das publicações"
                value={message}
                onChange={(e) => setMessage(e?.target?.value)}
              />
              <ButtonWrapper disabled={disabled}>
                <Button
                  primary
                  loading={sending}
                  onClick={action}
                  marginRight={"12px"}
                >
                  CONTINUAR
                </Button>
              </ButtonWrapper>
            </div>
          </>
        )}

        {step === 1 && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <img
                src="/images/comeback-arrow.png"
                width={"10px"}
                height={"18px"}
                style={{ cursor: "pointer" }}
                onClick={() => setStep(0)}
              ></img>
              <h4 style={{ margin: 0, marginLeft: "20px" }}>
                Adicionar publicações
              </h4>
            </div>
            <div style={{ marginTop: "16px" }}>
              <div
                style={{
                  display: "flex",
                  borderRadius: "8px",
                  padding: "16px",
                  boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.16)",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src="/images/check-purple-white.png"
                  width={"40px"}
                  height={"40px"}
                ></img>
                <div style={{ marginLeft: "16px" }}>
                  <h6 style={{ fontSize: "14px", marginBottom: "4px" }}>
                    Incluir identificações
                  </h6>
                  <p style={{ fontSize: "12px", margin: 0 }}>
                    Para finalizar, inclua as identificações para cada publicação que será
                    adicionada.
                  </p>
                </div>
              </div>
              <ul
                style={{
                  display: "flex",
                  flexDirection: "column",
                  listStyle: "none",
                  padding: 0,
                }}
              >
                {Object.entries(post).map(([id, post]) => (
                  <li
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "16px",
                      borderRadius: "16px",
                      border: "1px solid rgba(204, 196, 216, 1)",
                      marginTop: "16px",
                    }}
                  >
                    <div
                      onClick={() => removePost(id)}
                      style={{
                        alignSelf: "end",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "24px",
                        height: "24px",
                        background: "rgba(244, 244, 244, 1)",
                        borderRadius: "32px",
                        marginBottom: "16px",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src="/icons/close.svg"
                        width={"10px"}
                        height={"10px"}
                        onClick={() => removePost(id)}
                      ></img>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        {/* <img
                          src={post.imageUrl || "/icons/menu4-off.svg"}
                          width={"48px"}
                          height={"48px"}
                          style={{
                            borderRadius: "32px",
                            marginRight: "8px",
                          }}
                        ></img> */}
                        <div>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <h6
                              style={{
                                fontSize: "20px",
                                fontWeight: 700,
                                margin: "0px 0px 4px",
                                marginRight: "8px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: 300,
                              }}
                            >
                              {post.link.replace("https://www.", "")}
                            </h6>
                            {post.socialNetwork === "instagram" && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "24px",
                                  height: "24px",
                                  borderRadius: "32px",
                                  border: "1.07px solid rgba(204, 196, 216, 1)",
                                  marginRight: "4px",
                                }}
                              >
                                <img
                                  src={"/images/insta-grey.png"}
                                  width={"12px"}
                                  height={"12px"}
                                ></img>
                              </div>
                            )}
                            {post.socialNetwork === "tiktok" && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "24px",
                                  height: "24px",
                                  borderRadius: "32px",
                                  border: "1.07px solid rgba(204, 196, 216, 1)",
                                }}
                              >
                                <img
                                  src={"/images/tiktok-grey.png"}
                                  width={"12px"}
                                  height={"12px"}
                                ></img>
                              </div>
                            )}
                            {post.socialNetwork === "youtube" && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "24px",
                                  height: "24px",
                                  borderRadius: "32px",
                                  border: "1.07px solid rgba(204, 196, 216, 1)",
                                }}
                              >
                                <img
                                  src={"/icons/galery.svg"}
                                  width={"12px"}
                                  height={"12px"}
                                ></img>
                              </div>
                            )}
                          </div>
                          <p style={{ fontSize: "12px" }}>
                            {post.socialNetwork}
                          </p>
                        </div>
                      </div>
                      {/* <div style={{ display: "flex", flexDirection: "row" }}>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={"/images/heart.png"}
                              width={"16px"}
                              height={"17px"}
                              style={{ marginRight: "4px" }}
                            ></img>
                            <p style={{ fontSize: "14px", margin: 0 }}>
                              Seguidores
                            </p>
                          </div>
                          <p
                            style={{
                              fontSize: "20px",
                              fontWeight: 700,
                              margin: 0,
                              background:
                                "linear-gradient(90.02deg, #AF00E3 11.75%, #FF00D6 80.59%, #FF00D6 81.46%)",
                              "-webkit-text-fill-color": "transparent",
                              "-webkit-background-clip": "text",
                            }}
                          >
                            {post.totalFollowers >= 1e6
                              ? `${Math.floor(
                                post.totalFollowers / 1e6
                              )}M`
                              : post.totalFollowers >= 1e3
                                ? `${Math.floor(
                                  post.totalFollowers / 1e3
                                )}K`
                                : post.totalFollowers}
                          </p>
                        </div>

                        {!post.totalViews ? null : (
                          <div style={{ marginLeft: "24px" }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={"/images/button-play.png"}
                                width={"16px"}
                                height={"17px"}
                                style={{ marginRight: "4px" }}
                              ></img>
                              <p
                                style={{
                                  fontSize: "14px",
                                  margin: 0,
                                }}
                              >
                                Views
                              </p>
                            </div>
                            <p
                              style={{
                                fontSize: "20px",
                                fontWeight: 700,
                                margin: 0,
                                background:
                                  "linear-gradient(90.02deg, #AF00E3 11.75%, #FF00D6 80.59%, #FF00D6 81.46%)",
                                "-webkit-text-fill-color": "transparent",
                                "-webkit-background-clip": "text",
                              }}
                            >
                              {post.totalViews >= 1e6
                                ? `${Math.floor(post.totalViews / 1e6)}M`
                                : post.totalViews >= 1e3
                                  ? `${Math.floor(post.totalViews / 1e3)}K`
                                  : post.totalViews}
                            </p>
                          </div>
                        )}
                      </div> */}
                    </div>
                    <Input
                      label="Informar título"
                      hasAsterisk
                      placeholder="Digite um título de identificação para esse post"
                      labelPadding="0"
                      labelMargin="6px 0px 8px 0px"
                      value={post.title}
                      onChange={(e) =>
                        setPostTitle(id, e?.target?.value)
                      }
                    ></Input>

                    <Input
                      label="Código de impulsionamento"
                      placeholder="Digite um código de impulsionamento para esse post"
                      labelPadding="0"
                      labelMargin="6px 0px 8px 0px"
                      value={post.boostCode}
                      onChange={(e) =>
                        setPostCodeBoost(id, e?.target?.value)
                      }
                    ></Input>
                  </li>
                ))}
              </ul>
              <ButtonWrapper disabled={disabledCreatePosts}>
                <Button
                  primary
                  loading={sending}
                  onClick={createPosts}
                  marginRight={"12px"}
                >
                  ADICIONAR PUBLICAÇÕES
                </Button>
              </ButtonWrapper>
            </div>
          </>
        )}
      </CardExpandContainerBody>
    </CardExpandContainer>
  );
}
