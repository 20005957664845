import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import { FormTitle, FormText, FormSpacer, ActionText, TermText } from "./styled";

import Button from "components/Form/Button";
import Input, { MaskedInput } from "components/Form/Input";

import ContainerUnauthenticated from "containers/Unauthenticated";
import { DoRegister } from "services/authentication";
import { exposeStrapiError } from "utils";
import { BodyContent, BodyContentImage, FormDecoration } from "ui/styled";
import PasswordValidation from "components/Form/PasswordValidation";
import Check from "components/Form/Check";
import ErrorToast from "components/Toasts/Error";
import SuccessToast from "components/Toasts/Success";
import { GoogleDocsViewer } from "components/Viewer";
import { CoreContext } from "context/CoreContext";

export default function Register() {
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);
  const { setModal } =
    useContext(CoreContext);

  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({});
  const formValue = (ref) => {
    return form?.[ref] ? form?.[ref] : "";
  };
  const changeForm = (value, ref) => {
    setForm({ ...form, [ref]: value });
  };

  const valid = (verbose = false) => {
    if (!formValue("name") || !formValue("name").length) {
      if (verbose) {
        ErrorToast({ message: "Preencha o campo: Nome" });
      }
      return false;
    }

    if (!formValue("email") || !formValue("email").length) {
      if (verbose) {
        ErrorToast({ message: "Preencha o campo: Email" });
      }
      return false;
    }

    if (!formValue("password") || !formValue("password").length) {
      if (verbose) {
        ErrorToast({ message: "Preencha o campo: Senha" });
      }
      return false;
    }

    if (!formValue("terms")) {
      if (verbose) {
        ErrorToast({ message: "Aceite os termos" });
      }
      return false;
    }

    return true;
  };

  const action = async () => {
    if (!valid(true)) {
      return;
    }
    setLoading(true);

    const result = await DoRegister({
      ...form,
      username: form.email?.replace(/ /g, ""),
      email: form.email?.replace(/ /g, ""),
      confirmed: true,
      blocked: false,
      role: 1,
    });

    setLoading(false);
    if (result && !exposeStrapiError(result)) {
      completeLogin();
    }
  };

  const completeLogin = () => {
    SuccessToast({ message: "Conta criada com sucesso" });
    navigate("login");
  };

  return (
    <>
      <ContainerUnauthenticated>
        <BodyContent>
          <FormDecoration />
          <BodyContentImage />
          <FormTitle>Cadastre-se</FormTitle>
          <FormText>É muito bom te ver por aqui!</FormText>
          <Input
            placeholder="Nome completo"
            id={"name"}
            value={formValue("name")}
            onChange={(e) => changeForm(e.target.value, "name")}
          />
          <FormSpacer />
          <Input
            placeholder="E-mail"
            id={"email"}
            value={formValue("email")}
            onChange={(e) => changeForm(e.target.value, "email")}
          />
          <FormSpacer />
          <MaskedInput
            mask={"(99) 99999-9999"}
            placeholder="Telefone"
            id={"phone"}
            value={formValue("phone")}
            onChange={(e) => changeForm(e.target.value, "phone")}
          />
          <FormSpacer />
          <Input
            placeholder="Senha"
            id={"password"}
            type="password"
            value={formValue("password")}
            onChange={(e) => changeForm(e.target.value, "password")}
          />
          <FormSpacer />
          <PasswordValidation password={formValue("password")} />

          <Check
            checked={formValue("terms")}
            onChange={(e) => changeForm(!formValue("terms"), "terms")}
            customLabel
          >
            <TermText component="span" color="primary">
              Aceito os
            </TermText>

            <ActionText onClick={(event) => {
              event.stopPropagation();
              setModal({
                type: "terms",
                item: {
                  Viewer: GoogleDocsViewer,
                  props: { docUrl: "2PACX-1vR6TRW8beR_8rlsKnrGj4M8dvTzAJhii1wgm1bcphu1PnE3j-WMD3smtIzhqPmJRg" }
                },
              })
            }} rel="noopener noreferrer">
              termos de uso
            </ActionText>

            <TermText component="span" color="primary">
              e
            </TermText>

            <ActionText onClick={(event) => {
              event.stopPropagation();
              setModal({
                type: "terms",
                item: {
                  Viewer: GoogleDocsViewer,
                  props: { docUrl: "2PACX-1vQ6i2ME1MUsrGTUVdLspz64rZAkKPjAuuRrG2hoL3BBgepuJ10hNnqu1ZA-5IcPQg" }
                },
              })
            }} rel="noopener noreferrer">
              políticas de privacidade
            </ActionText>
          </Check>

          <FormSpacer />
          <Button primary loading={loading} onClick={action}>
            CONTINUAR
          </Button>
        </BodyContent>
      </ContainerUnauthenticated>
    </>
  );
}
