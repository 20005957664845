import ErrorToast from "components/Toasts/Error";
import { createContext, useCallback, useEffect, useState } from "react";
import { formatDateUsa } from "utils/formatter";
import { isBeforeToday, isEmail, isPhone } from "utils/validators";
import { ReadObject, SaveObject } from "../services/storage";
export const CoreContext = createContext({});

export const CoreState = ({ children }) => {
  const [modal, setModal] = useState(null);
  const [reloading, setReloading] = useState(null);
  const [user, setUser] = useState(
    ReadObject("user") ? ReadObject("user") : []
  );
  const [form, setForm] = useState(
    ReadObject("form") ? ReadObject("form") : {}
  );
  const [assets, setAssets] = useState(
    ReadObject("assets") ? ReadObject("assets") : []
  );

  const [formError, setFormError] = useState(
    ReadObject("formError") ? ReadObject("formError") : {}
  );

  const addAsset = useCallback(
    (asset) => {
      // console.log("New file", asset)
      setAssets([...assets, asset]);
      setReloading(true);
    },
    [assets]
  );
  const replaceAsset = useCallback(
    (assets) => {
      setAssets(assets);
      setReloading(true);
    },
    [assets]
  );
  const removeAsset = useCallback(
    (id) => {
      setAssets([...assets].filter((f) => f.id !== id));
      setReloading(true);
    },
    [assets]
  );

  useEffect(() => {
    if (reloading) {
      const timer = setTimeout(() => {
        setReloading(false);
      }, 100);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [reloading]);

  const cleanForm = () => {
    setForm({});
    setAssets([]);
    setFormError({});
  };

  const checkAllSteps = (type) => {
    type = type || form?.campaing_type;
    const errors = {};
    validateStep0(errors);
    validateStep1(errors, type);
    validateStep2(errors);
    validateStep3(errors);
    setFormError(errors);
    return !Object.keys(errors).length;
  };

  const checkFormStep = (step, type) => {
    const errors = {};
    switch (step) {
      case 0:
        validateStep0(errors);
        break;
      case 1:
        validateStep1(errors, type);
        break;
      case 2:
        validateStep2(errors);
        break;
      case 3:
        validateStep3(errors);
        break;
      default:
        break;
    }
    setFormError(errors);
    return !Object.keys(errors).length;
  };
  const validateStep0 = (errors) => {
    if (!form?.company) errors.company = true;
    if (!form?.email || !isEmail(form?.email)) errors.email = true;
    if (!form?.phone || !isPhone(form?.phone)) errors.phone = true;
    if (!form?.description) errors.description = true;
  };

  const validateStep1 = (errors, type) => {
    if (!form?.name) errors.name = true;
    if (!form?.objective) errors.objective = true;
    if (!form?.target_public) errors.target_public = true;

    if (type === "creative-ads" || type === "pontual-campaing") {
      if (!form?.expectation || isBeforeToday(formatDateUsa(form?.expectation)))
        errors.expectation = true;
    }

    if (type === "video-commerce") {
      if (!form?.logistic) errors.logistic = true;
      if (!form?.tom) errors.tom = true;
      if (!form?.videoSpecs) errors.videoSpecs = true;
      if (!form?.videoObs) errors.videoObs = true;
      if (!form?.characteristics) errors.characteristics = true;
      if (!form?.notalloed) errors.notalloed = true;
      if (assets.length === 0) errors.assets = true;
      if (!form?.knowing) errors.knowing = true;
    }

    if (type === "pontual-campaing" || type === "always-on") {
      if (!form?.budget) errors.budget = true;
      if (!form?.characteristics) errors.characteristics = true;
      if (!form?.campaingTheme) errors.campaingTheme = true;
      if (!form?.main_message) errors.main_message = true;
      if (!form?.successIndicator) errors.successIndicator = true;
      if (!form?.restritions) errors.restritions = true;
      if (form?.restritions === "yes" && !form?.restritions_description)
        errors.restritions_description = true;
      if (!form?.allowed) errors.allowed = true;
      if (!form?.notalloed) errors.notalloed = true;
      if (!form?.duration && type === "always-on") errors.duration = true;
      if (
        !form?.paymentDeadline ||
        isBeforeToday(formatDateUsa(form?.paymentDeadline))
      )
        errors.paymentDeadline = true;
    }

    if (type !== "video-commerce") {
      if (
        errors.paymentDeadline ||
        (errors.duration && type != "always-on") ||
        errors.expectation
      ) {
        ErrorToast({
          message:
            "Data inválida: A data inserida não pode ser menor que a data atual. ",
        });
      }
    }
  };
  const validateStep2 = (errors) => {
    if (!form?.other) errors.other = true;
    if (!form?.other_description && form?.other?.title == "Outro")
      errors.other_description = true;
  };

  const validateStep3 = (errors) => {
    if (!form?.company_site) errors.company_site = true;
    if (!form?.terms) errors.terms = true;
  };

  const checkSelectFields = (value, ref) => {
    if (ref === "knowing") {
      setFormError({ ...formError, knowing: !value });
    }
    if (ref === "restritions") {
      setFormError({ ...formError, restritions: false });
    }
    if (ref === "tom") {
      setFormError({ ...formError, tom: false });
    }
    if (ref === "logistic") {
      setFormError({ ...formError, logistic: false });
    }

    if (ref === "other") {
      if (value?.title !== "Outro") {
        setFormError({ ...formError, other_description: false });
      }
      setFormError({ ...formError, other: false });
    }

    if (ref === "terms") {
      setFormError({ ...formError, terms: !value });
    }
    if (ref === "duration") {
      setFormError({ ...formError, duration: false });
    }
  };

  const contextValue = {
    user,
    setUser,
    form,
    setForm,
    modal,
    setModal,
    assets,
    setAssets,
    addAsset,
    removeAsset,
    reloading,
    setFormError,
    formError,
    checkAllSteps,
    checkFormStep,
    checkSelectFields,
    cleanForm,
    replaceAsset,
  };

  // to persist state when app reload
  useEffect(() => {
    SaveObject("user", user);
  }, [user]);
  useEffect(() => {
    SaveObject("form", form);
  }, [form]);
  useEffect(() => {
    SaveObject("assets", assets);
  }, [assets]);
  useEffect(() => {
    SaveObject("formError", formError);
  }, [formError]);
  return (
    <CoreContext.Provider value={contextValue}>{children}</CoreContext.Provider>
  );
};
