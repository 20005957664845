import { CoreContext } from "context/CoreContext";
import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import SuccessToast from "components/Toasts/Success";
import { Create, ReadAll, ReadOne, Update } from "services/campaigns";
import { Create as CreateFile, Delete } from "services/projects-files";
import {
  exposeStrapiError,
  normalizeStrapiList,
  normalizeStrapiRegister,
} from "utils";
import { LABELS_BY_TYPE, moods } from "utils/mock";
import { parseDatestringUSBR } from "utils/parsers";

export default function useCampaing() {
  const params = useParams();

  const { form, user, assets } = useContext(CoreContext);

  const [currentCampaing, setCurrentCampaing] = useState(null);

  const [campaings, setCampaings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [empty, setEmpty] = useState(true);

  const parseCampaing = (item) => {
    const currentMood = moods?.find(
      (fnd) =>
        fnd.details_history === item?.details_history ||
        fnd.details_testimonials === item?.details_testimonials ||
        fnd.details_media === item?.details_media ||
        fnd.other
    );

    return {
      ...item,
      label: LABELS_BY_TYPE[item?.status] || "",
      title: item?.name,
      text: item?.campaing_objective,
      date: !item?.expected_delivery_date
        ? null
        : parseDatestringUSBR(item?.expected_delivery_date),
      expected_delivery_date: item?.expected_delivery_date,
      mood: currentMood,
      other: currentMood,
    };
  };

  const save = async () => {
    const payload = {
      data: {
        company_company: form?.company,
        company_email: form?.email,
        company_phone: form?.phone,
        company_brand_description: form?.description,

        name: form?.name,
        campaing_objective: form?.objective,
        target_audience: form?.target_public,
        main_message: form?.main_message,
        expected_delivery_date: form?.expectation,
        campaing_type: params?.type || form?.campaing_type,
        campaing_budget: form?.budget,
        campaing_paymentDeadline: form?.paymentDeadline,
        campaing_duration: form?.duration,
        campaing_characteristics: form?.characteristics,
        campaing_campaingTheme: form?.campaingTheme,
        campaing_indicator: form?.successIndicator,
        campaing_restritions: form?.restritions,
        campaing_restritions_description: form?.restritions_description,
        campaing_allowed: form?.allowed,
        campaing_notalloed: form?.notalloed,

        campaing_videoSpecs: form?.videoSpecs,
        campaing_creative: form?.creative,
        campaing_tom: form?.tom,
        campaing_logistic: form?.logistic,
        campaing_videoObs: form?.videoObs,

        details_history: form?.other?.title === "História",
        details_testimonials: form?.other?.title === "Depoimento",
        details_media: form?.other?.title === "Peça de mídia",
        details_others: form?.other_description,

        url_site_landing_page: form?.company_site,
        cta: form?.cta,
        special_requirement: form?.special,
        team_nice: form?.team_nice,

        user: user?.id,
        status: "progress",
      },
    };
    setSaving(true);
    const result = !params?.id
      ? await Create(payload)
      : await Update(payload, params?.id);
    setSaving(false);

    if (result && !exposeStrapiError(result)) {
      if (assets?.length && result?.data?.id) {
        const promises = assets?.map((item) =>
          CreateFile({
            data: {
              campaign: result?.data?.id,
              file: item?.id,
            },
          })
        );
        await Promise.all(promises);
      }

      return true;
    }
    return false;
  };
  const parseForm = (data) => {
    return {
      company: data?.company_company,
      email: data?.company_email,
      phone: data?.company_phone,
      description: data?.company_brand_description,

      name: data?.name,
      objective: data?.campaing_objective,
      target_public: data?.target_audience,
      main_message: data?.main_message,
      expectation: data?.expected_delivery_date,
      campaing_type: data?.campaing_type,
      budget: data?.campaing_budget,
      paymentDeadline: data?.campaing_paymentDeadline,
      duration: data?.campaing_duration,
      characteristics: data?.campaing_characteristics,
      campaingTheme: data?.campaing_campaingTheme,
      successIndicator: data?.campaing_indicator,
      restritions: data?.campaing_restritions,
      restritions_description: data?.campaing_restritions_description,
      allowed: data?.campaing_allowed,
      notalloed: data?.campaing_notalloed,
      knowing: data?.campaing_knowing || true,
      videoSpecs: data?.campaing_videoSpecs,
      creative: data?.campaing_creative,
      tom: data?.campaing_tom,
      logistic: data?.campaing_logistic,
      videoObs: data?.campaing_videoObs,

      other: {
        title: data?.details_history
          ? "História"
          : data?.details_testimonials
          ? "Depoimento"
          : data?.details_media
          ? "Peça de mídia"
          : "Outro",
      },
      other_description: data?.details_others,
      company_site: data?.url_site_landing_page,
      cta: data?.cta,
      special: data?.special_requirement,
      team_nice: data?.team_nice,
      terms: data?.terms || true,
    };
  };
  const init = useCallback(async () => {
    setLoading(true);

    if (params?.id) {
      const sRegister = await ReadOne(params?.id);

      if (!sRegister || !sRegister?.data?.id) {
        setLoading(false);
        return;
      }

      const normalSReg = normalizeStrapiRegister(sRegister);
      const parsedCampaing = parseCampaing(normalSReg);

      setEmpty(!normalSReg.deliveries?.length);

      setCurrentCampaing({
        ...parsedCampaing,
        assets: normalSReg.projects_files.map((pf) => ({
          ...pf,
          file: { id: pf.file.data.id, ...pf.file.data.attributes },
        })),
        deliveries: normalSReg.deliveries.map((dl) => ({
          ...dl,
          file: { id: dl.file.data.id, ...dl.file.data.attributes },
        })),
      });

      setLoading(false);
      return;
    }

    const result = await ReadAll(user?.id);

    if (result && !exposeStrapiError(result)) {
      const normalResult = normalizeStrapiList(result);

      setCampaings(normalResult?.map(parseCampaing));
      if (!params?.id) {
        setEmpty(!normalResult?.length);
      }
    }

    setLoading(false);
  }, [params, user]);

  const removeAsset = async (id) => {
    setLoading(true);
    const result = await Delete(id);
    if (result && !exposeStrapiError(result)) {
      SuccessToast({ message: "Removido" });
      init();
    }
  };

  useEffect(() => {
    init();
  }, [init]);

  return {
    save,
    empty,
    loading,
    campaings,
    currentCampaing,
    reload: init,
    saving,
    removeAsset,
    parseForm,
  };
}
