import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

import { FormHelperText } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { ThemedComponent } from "ui/theme";
import {
  AsteriskLabel,
  InputLabelOut,
  LabelContainer,
  MaterialSelect,
} from "./styled";

export const Select = ({
  options,
  small,
  value,
  onChange,
  id,
  secondary,
  label,
  labelPadding,
  labelMargin,
  backgroundColor,
  placeholder,
  labelSize,
  labelColor,
  variant = "",
  hasAsterisk = false,
  isFloat = false,
  errorMessage = "Campo obrigatório",
  hasError,
}) => {
  const [opened, setOpened] = useState(false);
  const [invalidValue, setInvalidValue] = useState(false);
  const isRequired = hasAsterisk;

  useEffect(() => {
    setInvalidValue(hasError);
  }, [hasError]);

  const toggleOpen = () => {
    setOpened(!opened);
  };

  const optionAction = (item) => {
    if (onChange && typeof onChange === "function") {
      onChange(item.target.value);
    }
    toggleOpen();
  };

  return (
    <>
      <ThemedComponent>
        {label && !isFloat && (
          <InputLabelOut
            labelPadding={labelPadding}
            labelMargin={labelMargin}
            labelSize={labelSize}
            labelColor={labelColor}
          >
            {label}
          </InputLabelOut>
        )}

        <FormControl
          variant={variant}
          fullWidth
          error={invalidValue && isRequired}
        >
          {isFloat && (
            <InputLabel
              sx={{
                fontSize: labelSize || "16px",
                transform: "translate(0px, 16px) scale(1)",
                "&.MuiInputLabel-shrink": {
                  fontSize: labelSize || "16px",
                  transform: "translate(0px, -6px) scale(1)",
                },
              }}
              id={id}
              color={secondary ? "secondary" : "primary"}
            >
              <LabelContainer>
                <span
                  style={
                    labelSize
                      ? {
                          fontSize: labelSize,
                        }
                      : {}
                  }
                >
                  {placeholder}
                </span>
                {hasAsterisk && (
                  <AsteriskLabel
                    style={
                      labelSize
                        ? {
                            fontSize: labelSize,
                          }
                        : {}
                    }
                  >
                    *
                  </AsteriskLabel>
                )}
              </LabelContainer>
            </InputLabel>
          )}
          <MaterialSelect
            displayEmpty
            small={small}
            labelId={id}
            color={secondary ? "secondary" : "primary"}
            id={`select-${id}`}
            value={value}
            onChange={optionAction}
            backgroundColor={backgroundColor}
          >
            {!isFloat && (
              <MenuItem disabled value="">
                {placeholder ?? "Selecione"}
              </MenuItem>
            )}
            {options?.map((item, key) => (
              <MenuItem key={key} value={item.id}>
                {item?.title}
              </MenuItem>
            ))}
          </MaterialSelect>
          {invalidValue && isRequired ? (
            <FormHelperText>{errorMessage}</FormHelperText>
          ) : null}
        </FormControl>
      </ThemedComponent>
    </>
  );
};

Select.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.object,
  small: PropTypes.bool,
  secondary: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

Select.defaultProps = {
  placeholder: "",
  options: [],
  value: undefined,
  small: false,
  secondary: false,
  onChange: undefined,
};

export default Select;
