import styled from 'styled-components'
import { Typography } from "@mui/material";

export const FormTitle = styled.div.attrs({
})`           
    color: ${props => props.theme.palette.primary.main}; 
    font-weight: bold;
    font-size: 20px; 

    margin: 32px 0 10px;
`;
export const FormText = styled.div.attrs({
})`           
    font-size: 15px; 
    color: ${props => props.theme.palette.colors.grey};
    margin-bottom: 30px;
`;

export const FormSpacer = styled.div.attrs({
})`           
    margin-top: 24px;
`;

export const RegisterCall = styled.div.attrs({
})`           
    margin: 30px 0 0px;
    color: ${props => props.theme.palette.colors.grey};
    font-size: 15;
    font-weight: bold;
`;

export const ActionText = styled("a")(({ theme }) => ({
    textDecoration: "none",
    color: theme.palette.primary.main,
    cursor: "pointer",
    color: theme.palette.secondary.main,
    textDecoration: "underline",
    marginLeft: 5,
    marginRight: 5,
    fontSize: 14,
}));

export const TermText = styled(Typography).attrs({
    fontSize: 14,
})``;