import React, { useEffect, useState, useContext } from "react";
import {
  CardExpandContainer,
  CardExpandContainerHeader,
  CardExpandContainerHeaderContent,
  CardExpandContainerBody,
  ModalContainerHeaderButtonIcon,
  ModalContainerHeaderButton,
  ButtonWrapper,
} from "./styled";

import Button from "components/Form/Button";
import Input from "components/Form/Input";
import { Update } from "services/campaigns";
import SuccessToast from "components/Toasts/Success";
import ErrorToast from "components/Toasts/Error";

import { CoreContext } from "context/CoreContext";

export default function CardEditPost({ closeable, currentPost, reload, campaign }) {
  const [sending, setSending] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [disabledCreatePosts, setDisabledCreatePosts] = useState(true);
  const { modal, setModal } = useContext(CoreContext);
  const [form, setForm] = useState(() => ({
    title: currentPost?.title,
    link: currentPost?.link,
    boostCode: currentPost?.boostCode,
  }));

  useEffect(() => {
    if (!form.title || !form.link) {
      setDisabledCreatePosts(true);
      return;
    }

    setDisabledCreatePosts(false);
  }, [form]);

  const setPostTitle = (id, title) => {
    setForm(prev => ({
      ...prev, title
    }));
  };

  const setPostCodeBoost = (id, boostCode) => {
    setForm(prev => ({
      ...prev, boostCode
    }));
  };

  const setPostLink = (id, link) => {
    setForm(prev => ({
      ...prev, link
    }));
  };

  const action = () => {
    try {
      if (disabledCreatePosts) {
        return;
      }
      const linkPost = form.link;

      if (linkPost.includes("tiktok")) {
        return {
          [currentPost.id]: {
            id: currentPost.id,
            link: linkPost,
            socialNetwork: "tiktok",
            title: form.title,
            boostCode: form.boostCode,
          }
        }
      }

      if (linkPost.includes("instagram")) {
        return {
          [currentPost.id]: {
            id: currentPost.id,
            link: linkPost,
            socialNetwork: "instagram",
            title: form.title,
            boostCode: form.boostCode,
          }
        }

      }

      if (linkPost.includes("youtube")) {
        return {
          [currentPost.id]: {
            id: currentPost.id,
            link: linkPost,
            socialNetwork: "youtube",
            title: form.title,
            boostCode: form.boostCode,
          }
        }
      }

    } catch (error) {
      setSending(false);

      ErrorToast({
        message: "Publicação não encontrada.",
      });
    }
  };

  const createPosts = async () => {
    setSending(true);
    try {
      if (disabledCreatePosts) {
        return;
      }

      const updatedPost = action()

      await Update(
        {
          data: {
            posts: {
              ...campaign.posts,
              ...updatedPost,
            },
          },
        },
        campaign.id
      );

      SuccessToast({ message: "Publicação editada com sucesso" });

      reload();

      closeable();

    } catch (error) {
      ErrorToast({
        message: "Erro ao editar publicação.",
      });
    } finally {
      setSending(false)
    }
  };

  return (
    <CardExpandContainer closeable={closeable}>
      <CardExpandContainerHeader>
        <CardExpandContainerHeaderContent></CardExpandContainerHeaderContent>
        {closeable ? (
          <ModalContainerHeaderButton>
            <Button outline nospace onClick={closeable}>
              <ModalContainerHeaderButtonIcon icon={"close"} />
            </Button>
          </ModalContainerHeaderButton>
        ) : null}
      </CardExpandContainerHeader>
      <CardExpandContainerBody>
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <h4 style={{ margin: 0, marginLeft: "20px" }}>
              Editar publicação
            </h4>
          </div>
          <div style={{ marginTop: "16px" }}>
            <div
              style={{
                display: "flex",
                borderRadius: "8px",
                padding: "16px",
                boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.16)",
                alignItems: "center",
              }}
            >
              <img
                src="/images/check-purple-white.png"
                width={"40px"}
                height={"40px"}
              ></img>
              <div style={{ marginLeft: "16px" }}>
                <h6 style={{ fontSize: "14px", marginBottom: "4px" }}>
                  Incluir identificações
                </h6>
                <p style={{ fontSize: "12px", margin: 0 }}>
                  Edite a publicação mantendo os campos obrigatórios.
                </p>
              </div>
            </div>
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                listStyle: "none",
                padding: 0,
              }}
            >
              <li
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "16px",
                  borderRadius: "16px",
                  border: "1px solid rgba(204, 196, 216, 1)",
                  marginTop: "16px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>

                    <div>
                      <div
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <h6
                          style={{
                            fontSize: "20px",
                            fontWeight: 700,
                            margin: "0px 0px 4px",
                            marginRight: "8px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: 300,
                          }}
                        >
                          {currentPost.link.replace("https://www.", "")}
                        </h6>
                        {currentPost.socialNetwork === "instagram" && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "24px",
                              height: "24px",
                              borderRadius: "32px",
                              border: "1.07px solid rgba(204, 196, 216, 1)",
                              marginRight: "4px",
                            }}
                          >
                            <img
                              src={"/images/insta-grey.png"}
                              width={"12px"}
                              height={"12px"}
                            ></img>
                          </div>
                        )}
                        {currentPost.socialNetwork === "tiktok" && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "24px",
                              height: "24px",
                              borderRadius: "32px",
                              border: "1.07px solid rgba(204, 196, 216, 1)",
                            }}
                          >
                            <img
                              src={"/images/tiktok-grey.png"}
                              width={"12px"}
                              height={"12px"}
                            ></img>
                          </div>
                        )}
                        {currentPost.socialNetwork === "youtube" && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "24px",
                              height: "24px",
                              borderRadius: "32px",
                              border: "1.07px solid rgba(204, 196, 216, 1)",
                            }}
                          >
                            <img
                              src={"/icons/galery.svg"}
                              width={"12px"}
                              height={"12px"}
                            ></img>
                          </div>
                        )}
                      </div>
                      <p style={{ fontSize: "12px" }}>
                        {currentPost.socialNetwork}
                      </p>
                    </div>
                  </div>

                </div>
                <Input
                  label="Informar link"
                  hasAsterisk
                  placeholder="Digite o link para esse post"
                  labelPadding="0"
                  labelMargin="6px 0px 8px 0px"
                  value={form.link}
                  onChange={(e) =>
                    setPostLink(currentPost.id, e?.target?.value)
                  }
                />

                <Input
                  label="Informar título"
                  hasAsterisk
                  placeholder="Digite um título de identificação para esse post"
                  labelPadding="0"
                  labelMargin="6px 0px 8px 0px"
                  value={form.title}
                  onChange={(e) =>
                    setPostTitle(currentPost.id, e?.target?.value)
                  }
                />

                <Input
                  label="Código de impulsionamento"
                  placeholder="Digite um código de impulsionamento para esse post"
                  labelPadding="0"
                  labelMargin="6px 0px 8px 0px"
                  value={form.boostCode}
                  onChange={(e) =>
                    setPostCodeBoost(currentPost.id, e?.target?.value)
                  }
                ></Input>
              </li>
            </ul>
            <ButtonWrapper disabled={disabledCreatePosts}>
              <Button
                primary
                loading={sending}
                onClick={createPosts}
                marginRight={"12px"}
              >
                EDITAR PUBLICAÇÃO
              </Button>
            </ButtonWrapper>
          </div>
        </>
      </CardExpandContainerBody>
    </CardExpandContainer>
  );
}
