import styledCmp from 'styled-components'

import { styled } from '@mui/material/styles';

import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';

export const MaterialCheckbox = styled(Checkbox)(({ theme }) => ({
}));

export const MaterialRadio = styled(Radio)(({ theme }) => ({
}));

export const CustomLabel = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    marginLeft: -16,
    flexWrap: "wrap",
    lineHeight: .5,
}));

export const CheckContainer = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
}));

