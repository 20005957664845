import styled from "styled-components";

export const ModalContainer = styled.div.attrs({})`
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  background: ${(props) => props.theme.palette.colors.darkshadow};
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
`;
