import { useContext, useState } from "react";

import { Grid, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Input from "components/Form/Input";
import { CoreContext } from "context/CoreContext";
import moment from "moment";
import { theme } from "ui/theme-color";
import { getFirstAndLastName } from "utils/formatString";
import {
  AssetsContent,
  AssetsContentItemContent,
  AssetsContentItemIcon,
  AssetsContentItemText,
  CardHomeContentLabel,
  CardHomeContentLabelDecoration,
  CardHomeContentLabelText,
} from "./styled";

export default function CardScriptList({ campaign, scripts, reload }) {
  const [scriptName, setScriptName] = useState();
  const { setModal } = useContext(CoreContext);

  const filterName = (item) => {
    if (!scriptName || scriptName === "") {
      return true;
    }

    const [_, itemObject] = Object.values(item);

    return (
      Object.values(itemObject).filter(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(scriptName.toLowerCase())
      ).length > 0
    );
  };

  return (
    <>
      <div
        style={{
          background: "rgba(255, 255, 255, 1)",
          borderRadius: "100px",
          maxWidth: "620px",
        }}
      >
        <Input
          withSearch
          rounded
          placeholder="Buscar roteiro"
          value={scriptName}
          onChange={(e) => setScriptName(e.target.value)}
        />
      </div>

      <AssetsContent>
        {Object.entries(scripts)
          .filter(filterName)
          .map(([id, item], key) => (
            <Grid
              container
              key={key}
              sx={{
                alignItems: "center",
                textAlign: "left",
                padding: "16px",
                borderBottom: `1px solid ${theme.palette.colors.borderline}`,
              }}
            >
              <Grid item xs={6} md={4}>
                <AssetsContentItemContent>
                  <AssetsContentItemIcon />
                  <AssetsContentItemText onClick={() => window.open(item.link)}>
                    {item?.title}
                  </AssetsContentItemText>
                </AssetsContentItemContent>
              </Grid>
              <Grid item xs={0} md={4}>
                {item.createBy != null && item.createdAt != null && (
                  <Tooltip title={item.createBy.name}>
                    <Typography
                      display={{ xs: "none", md: "block" }}
                      style={{ margin: 0, fontSize: "12px", marginLeft: "5px" }}
                    >
                      Enviado em {moment(item.createdAt).format("DD/MM/YYYY")}{" "}
                      às {moment(item.createdAt).format("HH:mm")}, por{" "}
                      {getFirstAndLastName(item.createBy.name)}
                    </Typography>
                  </Tooltip>
                )}
              </Grid>
              <Grid item xs={6} md={4}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <StatusLabel script={item} />
                  {item.approved !== null && item.approved !== undefined && (
                    <Tooltip title={item.createBy.name}>
                      <Typography
                        display={{ xs: "none", md: "block" }}
                        style={{
                          margin: 0,
                          fontSize: "12px",
                          marginLeft: "20px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Por{" "}
                        {item.approved
                          ? getFirstAndLastName(item.approvedBy.name)
                          : getFirstAndLastName(item.reprovedBy.name)}{" "}
                        {item.approved
                          ? moment(item.approvedAt).format("DD/MM/YYYY")
                          : moment(item.reprovedAt).format("DD/MM/YYYY")}{" "}
                        às{" "}
                        {item.approved
                          ? moment(item.approvedAt).format("HH:mm")
                          : moment(item.reprovedAt).format("HH:mm")}
                      </Typography>
                    </Tooltip>
                  )}
                  {(item.approved === undefined || item.approved === null) && (
                    <>
                      <img
                        src="/icons/approved-grey.svg"
                        width={"16px"}
                        height={"16px"}
                        style={{
                          marginLeft: "20px",
                          marginRight: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          setModal({
                            type: "approve-script",
                            item: { campaign, script: item },
                            reload,
                          })
                        }
                      />
                      <img
                        src="/icons/refused-grey.svg"
                        width={"16px"}
                        height={"16px"}
                        style={{
                          cursor: "pointer",
                          item: { campaign, script: item },
                        }}
                        onClick={() =>
                          setModal({
                            type: "reprove-script",
                            item: { campaign, script: item },
                            reload,
                          })
                        }
                      />
                    </>
                  )}
                  <img
                    src="/icons/trash-2.svg"
                    width={"16px"}
                    height={"16px"}
                    style={{
                      marginLeft: "20px",
                      marginRight: "16px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setModal({
                        type: "delete-script",
                        item: { campaign, script: item },
                        reload,
                      })
                    }
                  />
                </div>
              </Grid>
            </Grid>
          ))}
      </AssetsContent>
    </>
  );
}

function StatusLabel({ script }) {
  return (
    <CardHomeContentLabel isSuccess={script.approved}>
      <CardHomeContentLabelDecoration isSuccess={script.approved} />
      <CardHomeContentLabelText isSuccess={script.approved}>
        {script.approved === undefined || script.approved === null
          ? "Em aprovação"
          : script.approved
          ? "Aprovado"
          : "Reprovado"}
      </CardHomeContentLabelText>
    </CardHomeContentLabel>
  );
}
