import styled from "styled-components";
import { Typography } from "@mui/material";


export const AssetsContent = styled.div.attrs({})`
  margin: 24px 0 32px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 16px 0px #00000029;
`;

export const AssetsContentItem = styled.div.attrs({})`
  padding: 16px 0px 16px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.palette.colors.borderline};
  &:last-child {
    border: 0;
  }
`;

export const AssetsContentItemContent = styled.div.attrs({})`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const AssetsContentItemText = styled(Typography).attrs({
  fontSize: 14,
})`
  color: rgba(83, 57, 227, 1);
  cursor: pointer;
`;

export const AssetsContentItemBoostCode = styled(Typography).attrs({
  fontSize: 14,
})`
  color: ${p => p.theme.palette.colors.text};
  cursor: pointer;

  margin-right: 20px;
`;

export const AssetsContentItemAction = styled.div.attrs({})`
  padding: 8px 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.05);
  }
`;

export const AssetsContentItemFlexBox = styled.div.attrs({})`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 0 0 0 12px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`;

export const AssetsContentItemActionIcon = styled.img.attrs({
  src: "/icons/trash.svg",
  width: 16,
})``;

export const AssetsContentItemActionIconDownload = styled.img.attrs({
  src: "/icons/cloud-download.svg",
  width: 16,
})``;
