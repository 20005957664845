import { useContext, useEffect, useRef } from "react";

import {
  CardGalleryBody,
  CardGalleryBodyActions,
  CardGalleryBodyText,
  CardGalleryBodyTrashIcon,
  CardGalleryContent,
  CardGalleryImage,
  CardGalleryVideo,
} from "./styled";

import { Tooltip, Typography } from "@mui/material";
import Button from "components/Form/Button";
import { CoreContext } from "context/CoreContext";
import moment from "moment";
import { parseStrapiImage } from "utils";
import CardApproved from "../CardApproved";

export default function CardGallery({
  campaign,
  item,
  reload,
  removeAsset: removeAssetUp,
}) {
  const { setModal, user } = useContext(CoreContext);

  const openChat = () => {
    setModal({
      type: "chat",
      item: {
        ...item,
        campaignId: campaign.id,
      },
      reload,
    });
  };

  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      // Seek to 10 seconds
      videoRef.current.currentTime = 10;
      // Start playback
      videoRef.current.play();
    }
  }, []);

  const deleteDeliverables = () => {
    setModal({
      type: "delete-delivery",
      item: {
        ...item,
        campaignId: campaign.id,
      },
      reload,
      removeAsset: removeAssetUp,
    });
  };

  return (
    <>
      <CardGalleryContent>
        {item?.isVideo ? (
          <>
            <CardGalleryVideo
              ref={videoRef}
              controls={false}
              autoPlay={false}
              muted
              loop
            >
              <source
                src={parseStrapiImage(item?.file?.url)}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </CardGalleryVideo>
          </>
        ) : (
          <CardGalleryImage image={item?.file?.url} />
        )}
        <CardGalleryBody>
          {item.uploadAt != null && (
            <Tooltip title={item?.uploadBy?.data?.attributes?.name || " "}>
              <Typography
                display={{ xs: "none", md: "block" }}
                style={{
                  margin: 0,
                  fontSize: "12px",
                  marginLeft: "5px",
                }}
              >
                Enviado em: {moment(item.uploadAt).format("DD/MM/YYYY")} às{" "}
                {moment(item.uploadAt).format("HH:mm")}, por{" "}
                {item?.uploadBy?.data?.attributes?.name || " "}
              </Typography>
            </Tooltip>
          )}
          <CardGalleryBodyText>{item?.file?.name}</CardGalleryBodyText>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {item?.approved === undefined || item?.approved === null ? (
              <div
                style={{
                  fontWeight: 500,
                  color: "#5339E3",
                  cursor: "pointer",
                }}
                onClick={() =>
                  setModal({
                    type: "review",
                    item: {
                      ...item,
                      campaignId: campaign.id,
                      ratedBy: user.id,
                      ratedAt: new Date(),
                    },
                    reload,
                  })
                }
              >
                Avaliar entregável
              </div>
            ) : (
              <CardApproved approved={item?.approved} />
            )}
            {item.approved !== null && item.approved !== undefined && (
              <Tooltip title={item?.ratedBy?.data?.attributes?.name || " "}>
                <Typography
                  display={{ xs: "none", md: "block" }}
                  style={{
                    margin: 0,
                    fontSize: "12px",
                    marginLeft: "5px",
                  }}
                >
                  Por {item?.ratedBy?.data?.attributes?.name || " "}{" "}
                  {moment(item.approvedAt).format("DD/MM/YYYY")} às{" "}
                  {moment(item.approvedAt).format("HH:mm")}
                </Typography>
              </Tooltip>
            )}
          </div>

          <CardGalleryBodyTrashIcon
            data-tooltip-id="delete-delivery-tooltip"
            data-tooltip-content="Deletar entregável"
            onClick={deleteDeliverables}
          />
          <CardGalleryBodyActions>
            <Button
              primary
              outline
              onClick={
                item?.file?.url
                  ? () =>
                      window.open(
                        parseStrapiImage(item?.file?.url),
                        "campaing-asset"
                      )
                  : null
              }
            >
              DOWNLOAD
            </Button>
            <Button primary onClick={openChat}>
              VISUALIZAR
            </Button>
          </CardGalleryBodyActions>
        </CardGalleryBody>
      </CardGalleryContent>
    </>
  );
}
