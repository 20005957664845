import React, { useContext } from "react";

import {
  ModalContainer,
  ModalContainerBody,
  ModalContainerBodyContainer,
} from "./styled";

import { CoreContext } from "context/CoreContext";
import CardPosts from "components/Cards/Posts";
import CardEditPost from "components/Cards/EditPost";

export default function ModalPosts() {
  const { modal, setModal } = useContext(CoreContext);

  const close = () => {
    setModal(null);
  };

  return (
    <>
      <ModalContainer>
        <ModalContainerBodyContainer>
          <ModalContainerBody>
            {modal?.item?.type === "edit" ? <CardEditPost
              closeable={close}
              currentPost={modal?.item?.post}
              reload={modal?.reload}
              campaign={modal?.item?.campaign}
            /> : <CardPosts
              closeable={close}
              campaign={modal?.item}
              reload={modal?.reload}
            />}
          </ModalContainerBody>
        </ModalContainerBodyContainer>
      </ModalContainer>
    </>
  );
}
