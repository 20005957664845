export const isCpf = (c) => {
  if ((c = c.replace(/[^\d]/g, "")).length != 11) return false;
  if (c == "00000000000") return false;
  let r;
  let s = 0;
  for (let i = 1; i <= 9; i++) s = s + parseInt(c[i - 1]) * (11 - i);
  r = (s * 10) % 11;
  if (r == 10 || r == 11) r = 0;
  if (r != parseInt(c[9])) return false;
  s = 0;
  for (let i = 1; i <= 10; i++) s = s + parseInt(c[i - 1]) * (12 - i);
  r = (s * 10) % 11;
  if (r == 10 || r == 11) r = 0;
  if (r != parseInt(c[10])) return false;
  return true;
};

export const emailPattern =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const phonePattern = /^\(\d{2}\)\s?\d{4,5}[-\s]?\d{3,4}$/;
export const webSitePattern =
  /^(?:https?:\/\/)?(w{3}\.)?[\w_-]+((\.\w{2,}){1,2})(\/([\w\._-]+\/?)*(\?[\w_-]+=[^\?\/&]*(\&[\w_-]+=[^\?\/&]*)*)?)?$/;
export const isEmail = (email) => {
  return emailPattern.test(email);
};

export const isPhone = (phone) => {
  return phonePattern.test(phone);
};

export const isWebSite = (website) => {
  return webSitePattern.test(website);
};

export const isFullName = (name) => {
  const snps = name?.split(" ");
  return snps?.length > 1 && snps[0].length > 2 && snps[1].length > 2;
};

export const isPassword = (password) => {
  return (
    password?.match(/[-./',;&@#*)(_+:"´`~!]/) &&
    password?.length > 5 &&
    password?.match(/[0-9]/) &&
    password?.match(/[A-Z]/)
  );
};

export const isBeforeToday = (date) => {
  return new Date(date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
};
