import React, { useContext } from "react";

import { ModalContainer } from "./styled";

import { CoreContext } from "context/CoreContext";

export default function ModalTerms() {
  const { modal: { item: { Viewer, props } }, setModal } = useContext(CoreContext);

  const close = () => {
    setModal(null);
  };

  return (
    <>
      <ModalContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "12px",
            width: "800px",
            maxWidth: "60%",
            height: "80%",
            background: "rgba(255, 255, 255, 1)",
            borderRadius: "8px",
            padding: "16px",
          }}
        >
          <img
            src="/icons/close.svg"
            width={"10px"}
            height={"10px"}
            style={{ alignSelf: "end", cursor: "pointer", marginBottom: 20 }}
            onClick={close}
          />
          {Viewer && <Viewer {...props} />}
        </div>
      </ModalContainer>
    </>
  );
}
