import React, { useContext } from "react";

import {
  ContainerVideoHeader,
  ContainerVideoAction,
  ContainerVideoHeaderButtonIcon,
  StyledIframe
} from "./styled";

import ModalWrapper from "../Wrapper";

import Button from "components/Form/Button";
import { CoreContext } from "context/CoreContext";

export default function ModalMedia() {
  const { modal, setModal } = useContext(CoreContext);

  const close = () => {
    setModal(null);
  };

  return (
    <>
      <ModalWrapper headerActions={false}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              marginTop: "100px",
              display: "flex",
              flexDirection: "row-reverse",
              gap: "10px",
              overflow: "auto",
            }}
          >
            <ContainerVideoHeader>
              <ContainerVideoAction>
                <Button nospace primary onClick={close}>
                  <ContainerVideoHeaderButtonIcon icon={"close-white"} />
                </Button>
              </ContainerVideoAction>
            </ContainerVideoHeader>

            <StyledIframe
              z-index="0"
              width="475"
              height="844"
              src={modal?.item?.file?.url}
              frameborder="0"
              allow="accelerometer; autoplay"
              referrerpolicy="strict-origin-when-cross-origin"
            />
          </div>
        </div>
      </ModalWrapper>
    </>
  );
}
