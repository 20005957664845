import React, { useContext } from "react";

import {
  CardEmptyContainer,
  PostsCardEmptyImage,
  CardEmptyTitle,
  CardEmptyText,
} from "./styled";
import Button from "components/Form/Button";
import { CoreContext } from "context/CoreContext";

export default function PostsCardEmpty({ campaign, reload }) {
  const { setModal } = useContext(CoreContext);

  return (
    <>
      <CardEmptyContainer>
        <PostsCardEmptyImage />
        <CardEmptyTitle>Adicione publicações</CardEmptyTitle>
        <CardEmptyText>
          Adicione uma ou mais publicações por meio de seus links das mídias sociais
        </CardEmptyText>
        <div style={{ width: "fit-content", height: "48px", marginBottom: "32px" }}>
          <Button
            primary
            nospace
            onClick={() =>
              setModal({ type: "posts", item: campaign, reload })
            }
          >
            ADICIONAR PUBLICAÇÕES
          </Button>
        </div>
      </CardEmptyContainer>
    </>
  );
}
