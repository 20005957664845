export const campaingTypes = [
  {
    id: 1,
    type: "pontual-campaing",
    image: "/images/pontual-campaign.png",
    title: "Campanha pontual",
    text: "Tem aquele lançamento especial ou quer dar um boost nas redes sociais com criadores nativos? A Campanha Pontual é a ideal para você!",
  },
  {
    id: 5,
    type: "always-on",
    image: "/images/always-on.png",
    title: "Always On",
    text: "Tenha vídeos verticais para abastecer os perfis da sua marca e marcar presença de um jeito nativamente vertical. ",
  },
  {
    id: 2,
    type: "creative-ads",
    image: "/images/creative-ads.png",
    title: "Creative Ads",
    text: "Junte criatividade com performance através de assets criativos com criadores nativos em um formato vertical.",
  },
  {
    id: 3,
    type: "video-commerce",
    image: "/images/video-commerce.png",
    title: "Vídeo Commerce",
    text: "Mostre seus produtos no e-commerce e marketplace da mesma forma que os consumidores descobrem nas redes sociais.",
  },
  
];

export const moods = [
  {
    image: "/images/mood-1.png",
    title: "História",
    text: "Uma história live-action com enredo e diálogo entre atores",
    details_history: true,
    exampleVideoLink: "https://www.youtube.com/embed/tzoH7EIng8k",
  },
  {
    image: "/images/mood-2.png",
    title: "Depoimento",
    text: "Vídeo apresentando um produto e sua experiência de uso com ou sem ator",
    details_testimonials: true,
    exampleVideoLink: "https://www.youtube.com/embed/BmAmzSWcQvI",
  },
  {
    image: "/images/mood-3.png",
    title: "Peça de mídia",
    text: "Vídeo do ponto de vista do consumidor recomendando a compra",
    details_media: true,
    exampleVideoLink: "https://www.youtube.com/embed/SqBjQPyDEXY",
  },
  { image: "/images/mood-4.png", title: "Outro", other: true },
];

export const campaings = [
  {
    id: 1,
    label: "Andamento",
    title: "Space Pop Art",
    text: "Promover o lançamento dos novos fondues de Outback no TikTok.",
    date: "01/02/23",
    type: "creative-ads",
  },
  {
    id: 2,
    label: "Andamento",
    title: "Space Pop Art",
    text: "Promover o lançamento dos novos fondues de Outback no TikTok.",
    date: "01/02/23",
    type: "creative-ads",
  },
  {
    id: 3,
    label: "Andamento",
    title: "Space Pop Art",
    text: "Promover o lançamento dos novos fondues de Outback no TikTok.",
    date: "01/02/23",
    type: "pontual-campaing",
  },
  {
    id: 4,
    label: "Andamento",
    title: "Space Pop Art",
    text: "Promover o lançamento dos novos fondues de Outback no TikTok.",
    date: "01/02/23",
    type: "pontual-campaing",
  },
  {
    id: 5,
    label: "Finalizada",
    title: "Space Pop Art",
    text: "Promover o lançamento dos novos fondues de Outback no TikTok.",
    date: "01/02/23",
    type: "video-commerce",
  },
  {
    id: 6,
    label: "Finalizada",
    title: "Space Pop Art",
    text: "Promover o lançamento dos novos fondues de Outback no TikTok.",
    date: "01/02/23",
    type: "video-commerce",
  },
];

export const galleryContent = [
  {
    id: 1,
    status: "approved",
    image: "/images/gallery-1.png",
    text: "Entregável-1.mp4",
  },
  {
    id: 2,
    status: "refused",
    image: "/images/gallery-2.png",
    text: "Entregável-2.mp4",
  },
  {
    id: 3,
    image: "/images/gallery-3.png",
    text: "Entregável-3.mp4",
  },
];

export const LABELS_BY_TYPE = {
  progress: "Andamento",
  finished: "Finalizada",
  canceled: "Cancelada",
};
