export const formatDateUsa = (date) => {
  if (!date) return "";
  const [day, month, year] = date.split("/");
  return `${year}-${month}-${day}`;
};

export const formatDatePtBr = (date) => {
  if (!date) return "";
  const [year, month, day] = date.split("-");
  return `${day}/${month}/${year}`;
};

export const assetsFormatter = (asset) => {
  if (!asset.file) return asset;

  const formattedFormats = asset.file.formats
    ? Object.fromEntries(
        Object.entries(asset.file.formats).map(([key, value]) => [
          key,
          {
            ext: value.ext,
            url: value.url,
            hash: value.hash,
            mime: value.mime,
            name: value.name,
            path: value.path,
            size: value.size,
            width: value.width,
            height: value.height,
            provider_metadata: value.provider_metadata,
          },
        ])
      )
    : asset.file.formats;

  return {
    id: asset.id,
    name: asset.file.name,
    alternativeText: asset.file.alternativeText,
    caption: asset.file.caption,
    width: asset.file.width,
    height: asset.file.height,
    formats: formattedFormats,
    hash: asset.file.hash,
    ext: asset.file.ext,
    mime: asset.file.mime,
    size: asset.file.size,
    url: asset.file.url,
    previewUrl: asset.file.previewUrl,
    provider: asset.file.provider,
    provider_metadata: asset.file.provider_metadata,
    createdAt: asset.file.createdAt,
    updatedAt: asset.file.updatedAt,
  };
};
