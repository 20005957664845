import React, { useContext } from "react";
import { CoreContext } from "context/CoreContext";
import { GoogleDocsViewer } from "components/Viewer";

import Check from "components/Form/Check";
import { ActionText, TermText } from "./styled";

export default function CardTerms({ changeForm, formValue, preview }) {
    const { setModal } =
        useContext(CoreContext);


    return (
        <div
            onClick={
                preview ? null : () => changeForm(!formValue("terms"), "terms")
            }>
            <Check
                checked={!!formValue("terms") || preview}
            >

                <TermText component="span" color="primary">
                    Ao clicar em SALVAR você concorda que o seu briefing estará sujeito aos
                </TermText>

                <ActionText onClick={(event) => {
                    event.stopPropagation();
                    setModal({
                        type: "terms",
                        item: {
                            Viewer: GoogleDocsViewer,
                            props: { docUrl: "2PACX-1vR6TRW8beR_8rlsKnrGj4M8dvTzAJhii1wgm1bcphu1PnE3j-WMD3smtIzhqPmJRg" }
                        },
                    })
                }} rel="noopener noreferrer">
                    termos de uso
                </ActionText>

                <TermText component="span" color="primary">
                    e
                </TermText>

                <ActionText onClick={(event) => {
                    event.stopPropagation();
                    setModal({
                        type: "terms",
                        item: {
                            Viewer: GoogleDocsViewer,
                            props: { docUrl: "2PACX-1vQ6i2ME1MUsrGTUVdLspz64rZAkKPjAuuRrG2hoL3BBgepuJ10hNnqu1ZA-5IcPQg" }
                        },
                    })
                }} rel="noopener noreferrer">
                    políticas de privacidade
                </ActionText>

                <TermText component="span" color="primary">
                    da plataforma Nice For Brands
                </TermText>
            </Check >

        </div>
    );
}