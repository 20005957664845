export const format = {
  NumericFormat: {
    currency: {
      thousandSeparator: ".",
      decimalSeparator: ",",
      prefix: "R$ ",
      allowNegative: false,
      fixedDecimalScale: true,
      decimalScale: 2,
      isNumericString: true,
    },
  },
};
