import React, { useRef, useEffect } from "react";
import { StyledIframe } from "./styled";

const GoogleDocsViewer = ({ docUrl }) => {
  return (
    <StyledIframe
      src={`https://docs.google.com/document/d/e/${docUrl}/pub?embedded=true`}
      style={{
        width: "100%",
        height: "100%",
        border: "none",
        overflow: "hidden",
      }}
      title="Google Docs Viewer"
    />
  );
};

export default GoogleDocsViewer;
