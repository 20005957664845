import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  CampaingHeaderBreadContainer,
  CardHomeContentBodyAction,
} from "./styled";

import Button from "components/Form/Button";
import ContainerAuthenticated from "containers/Authenticated";

import CardAccordeon from "components/Cards/Accordeon";
import CardAssets from "components/Cards/Assets";
import CampaingHeaderBread from "components/Dashboard/Campaing/HeaderBread";
import { ReadAll } from "services/users";

import CardContentType from "components/Cards/ContentType";

import CardKnowing from "components/Cards/Knowing";
import CardTerms from "components/Cards/Terms";
import CardUpload from "components/Cards/Upload";
import ComboBox from "components/Form/ComboBox";
import Input, { MaskedInput, NumericInput } from "components/Form/Input";
import Radio from "components/Form/Radio";
import Select from "components/Form/Select";
import SuccessToast from "components/Toasts/Success";
import { CoreContext } from "context/CoreContext";
import useCampaing from "hooks/useCampaing";
import { Load } from "ui/styled";
import { theme } from "ui/theme-color";
import {
  assetsFormatter,
  formatDatePtBr,
  formatDateUsa,
} from "utils/formatter";
import { moods } from "utils/mock";
import { emailPattern, phonePattern, webSitePattern } from "utils/validators";
import {
  CheckText,
  ContainerSelect,
  UploadContentIcon,
  UploadContentText,
  UploadLabel,
} from "../CampaingCreate/styled";
const MONTH = Array.from({ length: 12 }, (_, index) => ({
  id: (index + 1).toString(),
  title: `${(index + 1).toString()} ${index === 0 ? "mês" : "meses"}`,
}));

const DURATION = {
  ref: "duration",
  options: MONTH,
  disabled: false,
};

export default function DashboardCampaingEdit() {
  const history = useHistory();
  const [originalAssetsSize, setOriginalAssetsSize] = useState(null);
  const { currentCampaing, loading, saving, save, parseForm, removeAsset } =
    useCampaing();
  const [users, setUsers] = useState([]);
  const {
    formError,
    checkAllSteps,
    checkSelectFields,
    setForm,
    form,
    assets,
    addAsset,
    setModal,
    replaceAsset,
    cleanForm,
  } = useContext(CoreContext);

  useEffect(() => {
    setForm(parseForm(currentCampaing));
    if (
      currentCampaing &&
      currentCampaing.assets &&
      currentCampaing.assets.length > 0
    ) {
      const assetIds = new Set(assets.map((asset) => asset.id));
      const newAssets = currentCampaing.assets
        .map(assetsFormatter)
        .filter((asset) => !assetIds.has(asset.id));
      if (newAssets.length > 0) {
        replaceAsset(newAssets);
      }
    }
    setOriginalAssetsSize(currentCampaing?.assets?.length || 0);
  }, [currentCampaing]);

  const formValue = (ref) => {
    return form?.[ref] ? form?.[ref] : "";
  };
  const changeForm = (value, ref) => {
    checkSelectFields(ref, value);
    setForm({ ...form, [ref]: value });
  };

  useEffect(async () => {
    const users = await ReadAll();

    setUsers(users);
  }, []);
  useEffect(() => {
    if (
      form &&
      currentCampaing &&
      !Object.keys(form)?.length &&
      Object.keys(currentCampaing)?.length
    ) {
      setForm({ ...currentCampaing });
    }
  }, [currentCampaing, form]);

  const action = async () => {
    if (checkAllSteps()) {
      const success = await save();
      if (success) {
        cleanForm();
        SuccessToast({ message: "Campanha atualizada com sucesso" });
        history.goBack();
      }
    }
  };

  const getInputError = (field) => {
    return formError[field] ? formError[field] : "";
  };

  return (
    <>
      <ContainerAuthenticated side={1}>
        <CampaingHeaderBreadContainer>
          <CampaingHeaderBread
            title={currentCampaing?.title}
            bread={["Campanhas", currentCampaing?.title, "Editar campanha"]}
            onBack={() => {
              setModal({
                type: "cancel-campaign",
                back: () => {
                  history.goBack();
                },
                cleanForm,
              });
            }}
          />

          {loading ? null : (
            <CardHomeContentBodyAction>
              <Button
                onClick={() => {
                  setModal({
                    type: "cancel-campaign",
                    back: () => {
                      history.goBack();
                    },
                    cleanForm,
                  });
                }}
                outline
                primary
              >
                CANCELAR
              </Button>
              <Button loading={saving} onClick={() => action()} primary>
                SALVAR
              </Button>
            </CardHomeContentBodyAction>
          )}
        </CampaingHeaderBreadContainer>

        {loading ? (
          <Load colored />
        ) : (
          <>
            <CardAccordeon title={"Informações do negócio"} label={"Andamento"}>
              <Input
                label={"Nome da empresa"}
                hasAsterisk
                placeholder="Digite o nome da empresa"
                hasError={getInputError("company")}
                errorMessage={"Campo obrigatório"}
                value={formValue("company")}
                onChange={(e) => changeForm(e.target.value, "company")}
              />
              <Input
                label={"E-mail de contato "}
                hasAsterisk
                placeholder="Digite o e-mail de contato"
                hasError={getInputError("email")}
                validationPattern={emailPattern}
                errorMessage={"E-mail inválido"}
                value={formValue("email")}
                onChange={(e) => changeForm(e.target.value, "email")}
              />
              <MaskedInput
                mask={"(99) 99999-9999"}
                label={"Telefone de contato "}
                hasAsterisk
                hasError={getInputError("phone")}
                validationPattern={phonePattern}
                errorMessage={"Telefone inválido"}
                placeholder="DDD + Número"
                value={formValue("phone")}
                onChange={(e) => changeForm(e.target.value, "phone")}
              />
              <Input
                type={"textarea"}
                label={"Descreva brevemente seu produto ou marca "}
                hasAsterisk
                hasError={getInputError("description")}
                placeholder="O que você quer destacar nesse projeto, pontos importantes e de destaque"
                value={formValue("description")}
                onChange={(e) => changeForm(e.target.value, "description")}
              />
            </CardAccordeon>

            <CardAccordeon title={"Time Nice"}>
              <ComboBox
                list={users}
                value={
                  Array.isArray(formValue("team_nice"))
                    ? formValue("team_nice")
                    : []
                }
                onChange={(event, newValue) =>
                  changeForm(newValue, "team_nice")
                }
              />{" "}
            </CardAccordeon>

            <CardAccordeon title={"Detalhes do projeto"}>
              {form.campaing_type === "video-commerce" ? (
                <>
                  <CardKnowing formValue={formValue} changeForm={changeForm} />
                  {getInputError("knowing") ? (
                    <span style={{ color: theme.palette.colors.red }}>
                      Campo obrigatório
                    </span>
                  ) : null}
                </>
              ) : null}

              <Input
                label={"Nome da campanha "}
                hasAsterisk
                placeholder="Digite aqui o nome da campanha"
                hasError={getInputError("name")}
                errorMessage={"Campo obrigatório"}
                value={formValue("name")}
                onChange={(e) => changeForm(e.target.value, "name")}
              />
              <Input
                label={"Objetivo da campanha "}
                hasAsterisk
                placeholder="Digite o objetivo da campanha"
                hasError={getInputError("objective")}
                errorMessage={"Campo obrigatório"}
                value={formValue("objective")}
                onChange={(e) => changeForm(e.target.value, "objective")}
              />
              <Input
                type={"textarea"}
                label={"Público alvo "}
                hasAsterisk
                placeholder="Descreva as personas que melhor se identificam com o seu público (idade, sexo, grau de escolaridade, classe social, hábitos)"
                hasError={getInputError("target_public")}
                errorMessage={"Campo obrigatório"}
                value={formValue("target_public")}
                onChange={(e) => changeForm(e.target.value, "target_public")}
              />

              {form.campaing_type === "creative-ads" ? (
                <>
                  <Input
                    type={"textarea"}
                    label={"Mensagem principal"}
                    placeholder="Tem alguma mensagem ou argumento que não pode faltar no vídeo?"
                    value={formValue("main_message")}
                    onChange={(e) => changeForm(e.target.value, "main_message")}
                  />
                  <Input
                    type={"date"}
                    label={"Expectativa de data de entrega "}
                    hasAsterisk
                    placeholder="Digite uma data ou selecione através do ícone de calendário"
                    hasError={getInputError("expectation")}
                    disabled
                    errorMessage={"Campo obrigatório"}
                    value={formValue("expectation")}
                    onChange={(e) => changeForm(e.target.value, "expectation")}
                  />
                </>
              ) : null}

              {form.campaing_type === "pontual-campaing" ||
              form.campaing_type === "always-on" ? (
                <>
                  <NumericInput
                    mask={"currency"}
                    label={"Budget "}
                    hasAsterisk
                    hasError={getInputError("budget")}
                    errorMessage={"Campo obrigatório"}
                    placeholder="Qual o valor disponível para criação da campanha? (Ex: R$ 30.000,00)"
                    value={formValue("budget")}
                    onChange={(e) => {
                      changeForm(e, "budget");
                    }}
                  />
                  <Input
                    type={"date"}
                    label={"Prazo do pagamento "}
                    hasAsterisk
                    placeholder="Digite uma data ou selecione através do ícone de calendário"
                    hasError={getInputError("paymentDeadline")}
                    errorMessage={"Campo obrigatório"}
                    value={formatDateUsa(formValue("paymentDeadline"))}
                    onChange={(e) =>
                      changeForm(
                        formatDatePtBr(e.target.value),
                        "paymentDeadline"
                      )
                    }
                  />
                  {form.campaing_type === "pontual-campaing" ? (
                    <Input
                      type={"date"}
                      label={"Data Estimada de Entrega "}
                      disabled
                      hasAsterisk
                      placeholder="Digite uma data ou selecione através do ícone de calendário"
                      hasError={getInputError("expectation")}
                      errorMessage={"Campo obrigatório"}
                      value={formValue("expectation")}
                      onChange={(e) =>
                        changeForm(e.target.value, "expectation")
                      }
                    />
                  ) : (
                    <ContainerSelect>
                      <Select
                        placeholder={"Duração da campanha"}
                        labelSize={20}
                        hasAsterisk
                        hasError={getInputError("duration")}
                        options={DURATION.options}
                        value={formValue(DURATION.ref)}
                        onChange={(val) => {
                          changeForm(val, DURATION.ref);
                        }}
                        disabled={DURATION.disabled}
                      />
                    </ContainerSelect>
                  )}
                  <Input
                    type={"textarea"}
                    label={"Características dos criadores "}
                    hasAsterisk
                    placeholder="Descreva características importantes entre os criadores de conteúdo ativos nessa campanha"
                    hasError={getInputError("characteristics")}
                    errorMessage={"Campo obrigatório"}
                    value={formValue("characteristics")}
                    onChange={(e) =>
                      changeForm(e.target.value, "characteristics")
                    }
                  />
                  <Input
                    label={"Tema central "}
                    hasAsterisk
                    placeholder="Descreva qual o tema central da sua campanha"
                    hasError={getInputError("campaingTheme")}
                    errorMessage={"Campo obrigatório"}
                    value={formValue("campaingTheme")}
                    onChange={(e) =>
                      changeForm(e.target.value, "campaingTheme")
                    }
                  />

                  <Input
                    type={"textarea"}
                    label={"Mensagem principal "}
                    hasAsterisk
                    placeholder="Descreva a principal mensagem da sua campanha"
                    hasError={getInputError("main_message")}
                    errorMessage={"Campo obrigatório"}
                    value={formValue("main_message")}
                    onChange={(e) => changeForm(e.target.value, "main_message")}
                  />
                  <Input
                    type={"textarea"}
                    label={"Indicadores de sucesso "}
                    hasAsterisk
                    hasError={getInputError("successIndicator")}
                    errorMessage={"Campo obrigatório"}
                    placeholder="Descreva os principais indicadores para medir o sucesso da campanha"
                    value={formValue("successIndicator")}
                    onChange={(e) =>
                      changeForm(e.target.value, "successIndicator")
                    }
                  />

                  <UploadLabel>
                    Existe alguma restrição legal/ regulatória sobre essa
                    campanha{" "}
                    <span style={{ color: theme.palette.colors.red }}>*</span>
                  </UploadLabel>
                  <Radio
                    label="Sim"
                    checked={formValue("restritions") === "yes"}
                    onChange={() => changeForm("yes", "restritions")}
                  />
                  <Radio
                    label="Não"
                    checked={formValue("restritions") === "no"}
                    onChange={() => changeForm("no", "restritions")}
                  />

                  {formValue("restritions") === "yes" ? (
                    <Input
                      type={"textarea"}
                      label={" "}
                      placeholder="Descreva quais são as restrições "
                      isRequired
                      hasError={getInputError("restritions_description")}
                      errorMessage={"Campo obrigatório"}
                      value={formValue("restritions_description")}
                      onChange={(e) =>
                        changeForm(e.target.value, "restritions_description")
                      }
                    />
                  ) : null}
                  {getInputError("restritions") ? (
                    <span style={{ color: theme.palette.colors.red }}>
                      Campo obrigatório
                    </span>
                  ) : null}
                  <Input
                    type={"textarea"}
                    label={"Do's "}
                    hasAsterisk
                    placeholder="Descreva o que é permitido"
                    hasError={getInputError("allowed")}
                    errorMessage={"Campo obrigatório"}
                    value={formValue("allowed")}
                    onChange={(e) => changeForm(e.target.value, "allowed")}
                  />
                  <Input
                    type={"textarea"}
                    label={"Dont's "}
                    hasAsterisk
                    placeholder="Descreva o que não é permitido"
                    hasError={getInputError("notalloed")}
                    errorMessage={"Campo obrigatório"}
                    value={formValue("notalloed")}
                    onChange={(e) => changeForm(e.target.value, "notalloed")}
                  />
                </>
              ) : null}

              {form.campaing_type === "video-commerce" ? (
                <>
                  <Input
                    type={"textarea"}
                    label={"Características dos criadores "}
                    hasAsterisk
                    hasError={getInputError("characteristics")}
                    errorMessage={"Campo obrigatório"}
                    placeholder="Descreva características importantes entre os criadores de conteúdo ativos nessa campanha"
                    value={formValue("characteristics")}
                    onChange={(e) =>
                      changeForm(e.target.value, "characteristics")
                    }
                  />
                  <Input
                    type={"textarea"}
                    label={
                      "Especificidades do(s) produto(s) que devem estar no video "
                    }
                    hasAsterisk
                    hasError={getInputError("videoSpecs")}
                    errorMessage={"Campo obrigatório"}
                    placeholder="Descreva quais são essas especificidades (Ex: Tipo de produto, medida, para qual a finalidade)"
                    value={formValue("videoSpecs")}
                    onChange={(e) => changeForm(e.target.value, "videoSpecs")}
                  />
                  <Input
                    type={"textarea"}
                    label={"Dont's "}
                    hasAsterisk
                    hasError={getInputError("notalloed")}
                    errorMessage={"Campo obrigatório"}
                    placeholder="Informe como o produto NÃO deve ser utilizado (Ex: Situações de uso ao qual o produto não é indicado para)"
                    value={formValue("notalloed")}
                    onChange={(e) => changeForm(e.target.value, "notalloed")}
                  />

                  <UploadLabel>
                    Tom de voz do video{" "}
                    <span style={{ color: theme.palette.colors.red }}>*</span>
                  </UploadLabel>
                  <Radio
                    label="Divertido e criativo"
                    checked={formValue("tom") === "creative"}
                    onChange={() => {
                      changeForm("creative", "tom");
                    }}
                  />
                  <Radio
                    label="Informativo"
                    checked={formValue("tom") === "info"}
                    onChange={() => changeForm("info", "tom")}
                  />
                  <Radio
                    label="Técnico"
                    checked={formValue("tom") === "tech"}
                    onChange={() => changeForm("tech", "tom")}
                  />
                  {getInputError("tom") ? (
                    <span style={{ color: theme.palette.colors.red }}>
                      Campo obrigatório
                    </span>
                  ) : null}
                  <UploadLabel>
                    Logística{" "}
                    <span style={{ color: theme.palette.colors.red }}>*</span>
                  </UploadLabel>
                  <Radio
                    label="Envio individual para creator"
                    checked={formValue("logistic") === "individual"}
                    onChange={() => changeForm("individual", "logistic")}
                  />
                  <Radio
                    label="Envio em lote para a Nice House distribuir entre os criadores"
                    checked={formValue("logistic") === "lote"}
                    onChange={() => changeForm("lote", "logistic")}
                  />
                  <Radio
                    label="Gravação presencial em Loja/Showroom"
                    checked={formValue("logistic") === "presential"}
                    onChange={() => changeForm("presential", "logistic")}
                  />
                  {getInputError("logistic") ? (
                    <span style={{ color: theme.palette.colors.red }}>
                      Campo obrigatório
                    </span>
                  ) : null}
                  <CardUpload
                    title={"Produtos que vão ser vinculados aos vídeos "}
                    hasAsterisk
                  >
                    <UploadContentIcon />
                    <UploadContentText>
                      Arraste ou clique para adicionar o arquivo <b>CSV</b> com
                      os produtos que você deseja vincular a esta campanha.
                    </UploadContentText>
                  </CardUpload>
                  {getInputError("assets") && currentCampaing?.assets == 0 ? (
                    <span style={{ color: theme.palette.colors.red }}>
                      Campo obrigatório
                    </span>
                  ) : null}
                  <CardAssets removable toCampaignCreation />

                  <CheckText flat>
                    Faça o{" "}
                    <CheckText link>
                      <a href="https://docs.google.com/spreadsheets/d/13YuCUsLPQKoHRcb-FmaX0c5eCePUyCK0XvR9I8HE-hc/export?format=xlsx">
                        download da planilha
                      </a>
                    </CheckText>{" "}
                    padrão para incluir seus produtos no briefing
                  </CheckText>

                  <Input
                    type={"textarea"}
                    label={"Observações "}
                    hasAsterisk
                    hasError={getInputError("videoObs")}
                    errorMessage={"Campo obrigatório"}
                    placeholder="Descreva alguma observação importante para a criação dos vídeos"
                    value={formValue("videoObs")}
                    onChange={(e) => changeForm(e.target.value, "videoObs")}
                  />
                </>
              ) : null}
            </CardAccordeon>

            <CardAccordeon title={"Detalhes do conteúdo"}>
              {moods?.map((item, key) => (
                <CardContentType
                  key={key}
                  item={item}
                  formValue={formValue}
                  changeForm={changeForm}
                />
              ))}
            </CardAccordeon>

            <CardAccordeon title={"Informações adicionais"}>
              <Input
                label={"Site de empresa / Landing page "}
                hasAsterisk
                hasError={getInputError("company_site")}
                validationPattern={webSitePattern}
                placeholder="Digite a URL (Ex: http://www.site.com.br)"
                value={formValue("company_site")}
                onChange={(e) => changeForm(e.target.value, "company_site")}
              />
              <Input
                type={"textarea"}
                label={"Call-to-action (CTA)"}
                placeholder="Qual ação você quer que seu público execute após assistir o seu vídeo? ( Ex: Clique aqui, Saiba mais, Baixe agora e etc...)"
                value={formValue("cta")}
                onChange={(e) => changeForm(e.target.value, "cta")}
              />
              <Input
                type={"textarea"}
                label={"Requerimento Especial"}
                placeholder="Informe qualquer requerimento específico incluindo ideias, conceitos, elementos visuais e etc..."
                value={formValue("special")}
                onChange={(e) => changeForm(e.target.value, "special")}
              />

              <CardUpload title={"Assets"} />
              <CardAssets removable toCampaignCreation />
              <CardTerms formValue={formValue} changeForm={changeForm} />
              {getInputError("terms") ? (
                <span style={{ color: theme.palette.colors.red }}>
                  Campo obrigatório
                </span>
              ) : null}
            </CardAccordeon>
          </>
        )}
      </ContainerAuthenticated>
    </>
  );
}
