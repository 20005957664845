import { useContext } from "react";

import { Grid, Tooltip, Typography } from "@mui/material";
import ErrorToast from "components/Toasts/Error";
import { CoreContext } from "context/CoreContext";
import moment from "moment";
import { theme } from "ui/theme-color";
import { parseStrapiImage } from "utils";
import { getFirstAndLastName } from "utils/formatString";
import CardApproved from "../CardApproved";
import {
  AssetsContent,
  AssetsContentItemAction,
  AssetsContentItemActionIcon,
  AssetsContentItemActionIconDownload,
  AssetsContentItemText,
  UploadLabel,
} from "./styled";

export default function CardAssets({
  campaign,
  title,
  removable,
  showableAssets,
  reload,
  removeAsset: removeAssetUp,
  showModal = false,
  toReview = false,
  toCampaignCreation = false,
}) {
  const { assets, removeAsset, reloading, setModal, user } =
    useContext(CoreContext);
  return !(showableAssets || assets)?.length ? null : (
    <>
      {!title ? null : <UploadLabel>{title}</UploadLabel>}

      <AssetsContent>
        {reloading
          ? null
          : (showableAssets || assets)?.map((item, key) => (
              <Grid
                container
                key={key}
                sx={{
                  alignItems: "center",
                  textAlign: "left",
                  padding: "8px",
                  borderBottom: `1px solid ${theme.palette.colors.borderline}`,
                }}
              >
                <Grid
                  item
                  xs={6}
                  md={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <img
                    src={
                      toCampaignCreation
                        ? item.url
                        : [".pdf", ".docx", ".xlsx", ".csv"].includes(
                            item.file?.ext
                          )
                        ? "/icons/document.svg"
                        : item.file?.previewUrl || item.file?.url
                    }
                    width="38px"
                    height="38px"
                    style={{ borderRadius: "12px" }}
                  />

                  <AssetsContentItemText>
                    {toCampaignCreation ? item.name : item?.file?.name}
                  </AssetsContentItemText>
                </Grid>
                <Grid item xs={0} md={4}>
                  {item.uploadAt != null && (
                    <Tooltip
                      title={item?.uploadBy?.data?.attributes?.name || " "}
                    >
                      <Typography
                        display={{ xs: "none", md: "block" }}
                        style={{
                          margin: 0,
                          fontSize: "12px",
                          marginLeft: "5px",
                        }}
                      >
                        Enviado em: {moment(item.uploadAt).format("DD/MM/YYYY")}{" "}
                        às {moment(item.uploadAt).format("HH:mm")}, por{" "}
                        {getFirstAndLastName(
                          item?.uploadBy?.data?.attributes?.name
                        ) || " "}
                      </Typography>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item xs={6} md={4}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      fontSize: "12px",
                    }}
                  >
                    {toReview ? (
                      item?.approved === undefined ||
                      item?.approved === null ? (
                        <div
                          style={{
                            fontWeight: 500,
                            color: "#5339E3",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            setModal({
                              type: "review",
                              item: {
                                ...item,
                                campaignId: campaign.id,
                                ratedBy: user.id,
                                ratedAt: new Date(),
                              },
                              reload,
                            })
                          }
                        >
                          Avaliar entregável
                        </div>
                      ) : (
                        <>
                          <CardApproved approved={item?.approved} />
                          <Tooltip
                            title={item?.ratedBy?.data?.attributes?.name || " "}
                          >
                            <Typography
                              display={{ xs: "none", md: "block" }}
                              style={{
                                margin: 0,
                                fontSize: "12px",
                                marginLeft: "5px",
                              }}
                            >
                              Por{" "}
                              {getFirstAndLastName(
                                item?.ratedBy?.data?.attributes?.name
                              ) || " "}{" "}
                              às {moment(item.approvedAt).format("DD/MM/YYYY")}{" "}
                              {moment(item.approvedAt).format("HH:mm")}
                            </Typography>
                          </Tooltip>
                        </>
                      )
                    ) : null}

                    <AssetsContentItemAction
                      onClick={() =>
                        !item?.file?.url
                          ? null
                          : window.open(
                              parseStrapiImage(item?.file?.url),
                              "campaing-asset"
                            )
                      }
                    >
                      <AssetsContentItemActionIconDownload />
                    </AssetsContentItemAction>
                    {!removable && !removeAssetUp ? null : (
                      <AssetsContentItemAction
                        onClick={async () => {
                          if (showModal) {
                            setModal({
                              type: "delete-delivery",
                              item: {
                                ...item,
                                campaignId: campaign.id,
                              },
                              reload,
                              removeAsset: removeAssetUp,
                            });
                          } else {
                            removeAssetUp
                              ? await removeAssetUp(item?.id)
                              : await removeAsset(item?.id);

                            ErrorToast({
                              message: `${item?.file?.name} removido`,
                            });
                          }
                          if (reload) {
                            reload();
                          }
                        }}
                      >
                        <AssetsContentItemActionIcon />
                      </AssetsContentItemAction>
                    )}
                  </div>
                </Grid>
              </Grid>
            ))}
      </AssetsContent>
    </>
  );
}
