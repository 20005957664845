import React, { useState, useContext } from "react";

import {
  AssetsContent,
  AssetsContentItem,
  AssetsContentItemContent,
  AssetsContentItemText,
  AssetsContentItemFlexBox,
  AssetsContentItemBoostCode
} from "./styled";

import Input from "components/Form/Input";
import { CoreContext } from "context/CoreContext";

import LinkIcon from '@mui/icons-material/Link';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from "@mui/material";
import { getFirstAndLastName } from "utils/formatString"
import moment from "moment";


export default function CardPostsList({ campaign, posts, reload }) {
  const [PostsName, setPostsName] = useState();
  const { setModal } = useContext(CoreContext);

  const filterName = (item) => {
    if (!PostsName || PostsName === "") {
      return true;
    }

    const [_, itemObject] = Object.values(item);

    return (
      Object.values(itemObject).filter(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(PostsName.toLowerCase())
      ).length > 0
    );
  };

  return (
    <>
      <div
        style={{
          background: "rgba(255, 255, 255, 1)",
          borderRadius: "100px",
          maxWidth: "620px",
        }}
      >
        <Input
          withSearch
          rounded
          placeholder="Buscar publicacão"
          value={PostsName}
          onChange={(e) => setPostsName(e.target.value)}
        />
      </div>

      <AssetsContent>
        {Object.entries(posts)
          .filter(filterName)
          .map(([id, item], key) => (
            <AssetsContentItem key={key}>
              <AssetsContentItemContent>
                <LinkIcon sx={{ height: 30, width: 30 }} />
                <AssetsContentItemFlexBox>
                  <AssetsContentItemText onClick={() => window.open(item.link)}>
                    {item?.title}
                  </AssetsContentItemText>

                  <AssetsContentItemBoostCode>
                    {item?.boostCode}
                  </AssetsContentItemBoostCode>
                </AssetsContentItemFlexBox>
              </AssetsContentItemContent>

              {item.createdBy && <Tooltip title={item.createdBy.name}>
                <Typography
                  display={{ xs: "none", md: "block" }}
                  style={{
                    margin: 0,
                    fontSize: "12px",
                    marginLeft: "40px",
                    whiteSpace: "nowrap"
                  }}
                >
                  Criado por{" "}
                  {getFirstAndLastName(item.createdBy.name)}{" "}
                  {item.createdAt
                    && `${moment(item.createdAt).format("DD/MM/YYYY")} às ${moment(item.createdAt).format("HH:mm")}
                  `}
                </Typography>
              </Tooltip>}

              <img
                src="/icons/edit.svg"
                width={"20px"}
                height={"20px"}
                style={{
                  marginLeft: "20px",
                  marginRight: "20px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  setModal({
                    type: "posts",
                    item: { campaign: campaign, post: item, type: "edit" },
                    reload,
                  })
                }
              />

              <img
                src="/icons/trash-2.svg"
                width={"16px"}
                height={"16px"}
                style={{
                  marginRight: "20px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  setModal({
                    type: "delete-post",
                    item: { campaign, post: item },
                    reload,
                  })
                }
              />
            </AssetsContentItem>
          ))}
      </AssetsContent>
    </>
  );
}
