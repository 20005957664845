import styled from 'styled-components'
import { Typography } from "@mui/material";

export const CheckContainer = styled.div.attrs({
})`
    display: flex;
    cursor: pointer;
`;
export const CheckText = styled.div.attrs({
})`
    display: flex;
    gap: 4px;
    font-size: 14px;
    color: ${p => p.link ? p.theme.palette.secondary.main : p.theme.palette.colors.text};
    ${p => p.link ? `
            cursor: pointer;
            &:hover{
                text-decoration: underline;
            }        
        ` : `
            margin: 10px 0 0 -20px;
        `
    }
    ${p => p.flat ? `
            margin: 10px 0 0 0px;  
        ` : `
        `
    }
`;

export const ActionText = styled("a")(({ theme }) => ({
    textDecoration: "none",
    color: theme.palette.primary.main,
    cursor: "pointer",
    color: theme.palette.secondary.main,
    textDecoration: "underline",
    marginLeft: 5,
    marginRight: 5,
    fontSize: 14,
}));

export const TermText = styled(Typography).attrs({
    fontSize: 14,
})``;

